import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Toggle Button */}
      <button
        className={`sidebar-toggle ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}
        onClick={toggleSidebar}
        aria-label="Toggle sidebar"
      >
        <FontAwesomeIcon icon={isSidebarOpen ? faAngleLeft : faAngleRight} />
      </button>

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
        <nav>
          <ul>
            <li>
              <NavLink
                to="/partner/dashboard/profile"
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                onClick={toggleSidebar} // Close sidebar on link click
              >
                <span className="icon">👤</span>
                <span className="label">{t('sidebar.profileManagement')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/partner/dashboard/statistics"
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                onClick={toggleSidebar}
              >
                <span className="icon">📊</span>
                <span className="label">{t('sidebar.statistics')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/partner/dashboard/products"
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                onClick={toggleSidebar}
              >
                <span className="icon">🛍️</span>
                <span className="label">{t('sidebar.products')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/partner/dashboard/sales-history"
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                onClick={toggleSidebar}
              >
                <span className="icon">💰</span>
                <span className="label">{t('sidebar.salesHistory')}</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
