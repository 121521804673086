import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './ComputerMenu.css'

const ComputerMenu = ({
  isAuthenticated,
  user,
  t,
  showNotifications,
  showDropdown,
  toggleDropdown,
  toggleNotifications,
  unreadCount,
  handleNotificationClick,
  handleDeleteNotification,
  handleDeleteAll,
  dropdownRef,
  bellIconRef,
  notificationsRef,
  languageDropdownRef,
  toggleLanguageDropdown,
  language,
  showLanguageDropdown,
  handleLanguageChange,
  setShowLogoutModal,
  handleSettings,
  handleWallet,
  handleHistory,
  handleAdmin,
}) => {
  return (
      <nav>
        {isAuthenticated && (
          <>
            {user.role !== 'partner' && (
              <Link className='become-partner-link' to="/becomepartner">{t('becomePartner')}</Link>
            )}
            {user.role === 'partner' && (
              <Link className='partner-dashboard-link' to="/partner/dashboard">{t('dashboard')}</Link>
            )}
          </>
        )}
        <Link className='home-link' to="/">{t('home')}</Link>
        <Link className='partners-link' to="/partners">{t('partners')}</Link>
        {isAuthenticated ? (
          <>
            <div className="notification-icon" onClick={toggleNotifications} ref={bellIconRef}>
              <FontAwesomeIcon icon={faBell} />
              {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
            </div>
            {showNotifications && (
            <div className="notification-dropdown-computer" ref={notificationsRef}>
              <div className="notification-header-computer">
                <h4>{t('notifications')}</h4>
                <button className="delete-all-computer" onClick={handleDeleteAll}>
                  {t('deleteAll')}
                </button>
              </div>
              {user.notifications.length > 0 ? (
                user.notifications.slice().reverse().map((notification, index) => (
                  <div
                    key={index}
                    className={`notification-item-computer ${
                      notification.read ? 'read' : 'unread'
                    }`}
                    onClick={() => handleNotificationClick(notification.link)}
                    style={{ cursor: notification.link ? 'pointer' : 'default' }}
                  >
                    <span>{notification.message}</span>
                    <button
                      className="delete-notification-computer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNotification(
                          user.notifications.length - 1 - index
                        );
                      }}
                    >
                      &times;
                    </button>
                  </div>
                ))
              ) : (
                <div className="notification-item-computer no-notifications-computer">
                  {t('noNotifications')}
                </div>
              )}
            </div>
          )}

            <div className="dropdown" ref={dropdownRef}>
              <button onClick={toggleDropdown}>{t('myAccount')}</button>
              {showDropdown && (
                <div className="dropdown-content">
                  <button onClick={handleSettings}>{t('settings')}</button>
                  <button onClick={handleWallet}>{t('wallet')}</button>
                  <button onClick={handleHistory}>{t('history')}</button>
                  {user.role === 'admin' && (
                    <button onClick={handleAdmin}>{t('admin')}</button>
                  )}
                  <button onClick={() => setShowLogoutModal(true)}>{t('logout')}</button>
                </div>
              )}
              <div className="user-wallet">
                {user?.wallet.amount !== undefined ? `${user.wallet.amount} €` : t('loadingWallet')}
              </div>
            </div>
          </>
        ) : (
          <Link className="login-link" to="/login">{t('login')}</Link>
        )}
        <div className="language-selector" ref={languageDropdownRef}>
          <button onClick={toggleLanguageDropdown}>
            {language === 'fr' ? 'FR' : 'EN'} <FontAwesomeIcon icon={faCaretDown} />
          </button>
          {showLanguageDropdown && (
            <div className="language-dropdown">
              <button onClick={() => handleLanguageChange('en')}>EN</button>
              <button onClick={() => handleLanguageChange('fr')}>FR</button>
            </div>
          )}
        </div>
      </nav>
  );
};

export default ComputerMenu;
