// src/App.js
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import CGU from './pages/CGU/CGU';
import ResetPassword from './pages/ResetPassword/ResetPassword'
import NewPassword from './pages/NewPassword/NewPassword';
import Home from './pages/Home/Home';
import Partners from './pages/Partners/Partners';
import BecomePartner from './pages/BecomePartner/BecomePartner';
import ViewPartner from './pages/ViewPartner/ViewPartner';
import DashboardPage from './pages/PartnerDashboard/Main/Main';
import Statistics from './pages/PartnerDashboard/Statistics/Statistics'
import Products from './pages/PartnerDashboard/Products/Products'
import SalesHistory from './pages/PartnerDashboard/SalesHistory/SalesHistory';
import ProfileManagement from './pages/PartnerDashboard/ProfileManagement/ProfileManagement'
import NotFound from './pages/NotFound/NotFound';
import Register from './pages/Register/Register';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ViewProduct from './pages/ViewProduct/ViewProduct';
import PostProductAdmin from './pages/Admin/PostProductAdmin/PostProductAdmin';
import Settings from './pages/Settings/Settings';
import Wallet from './pages/Wallet/Wallet';
import Refund from './pages/Refund/Refund'
import Success from './pages/Success/Success';
import Cancel from './pages/Cancel/Cancel';
import History from './pages/History/History'
import AdminDashboard from './pages/Admin/AdminDashboard/AdminDashboard';
import RefundRequestsManager from './pages/Admin/RefundRequestsManager/RefundRequestsManager';
import UserManager from './pages/Admin/UserManager/UserManager';
import ViewUserAdmin from './pages/Admin/ViewUserAdmin/ViewUserAdmin';
import PartnerApplicationManager from './pages/Admin/PartnershipApplicationManager/PartnershipApplicationManager';
import DeliveryManager from './pages/Admin/DeliveryManager/DeliveryManager';
import ViewDelivery from './pages/Admin/ViewDelivery/ViewDelivery';
import WebsiteStatistics from './pages/Admin/WebsiteStatistics/WebsiteStatistics';
import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import PartnerApplicationProcess from './pages/Admin/PartnerApplicationProcess/PartnerApplicationProcess';
import axios from 'axios';
import AdminRoute from './components/AdminRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute'
import {WebSocketProvider} from './context/WebSocketContext';
import AgeVerificationModal from './components/AgeVerificationModal/AgeVerificationModal';
import "./App.css"

const App = () => {
  axios.defaults.withCredentials = true;

  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem('isOver18') === 'true') {
      setIsModalOpen(false);
    }
  }, []);

  return (
    <AuthProvider>
      <LanguageProvider>
        <WebSocketProvider>
          <Router>
            <Header />
            <main className={isModalOpen ? 'blurred' : ''}>
              {/* Apply blur only to content behind the modal */}
              <div className={isModalOpen ? 'blurred-content' : ''}>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/cgu" element={<CGU />} />
                <Route path="/becomepartner" element={<BecomePartner />} />
                <Route path="/viewpartner/:partnerId" element={<ViewPartner />} />
                <Route path="/login" element={<Login />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/newpassword/:token" element={<NewPassword />} />
                <Route path="/wallet" element={<AuthenticatedRoute><Wallet /></AuthenticatedRoute>} />
                <Route path="/refund" element={<AuthenticatedRoute><Refund /></AuthenticatedRoute>} />
                <Route path="/settings" element={<AuthenticatedRoute><Settings /></AuthenticatedRoute>} />
                <Route path="/viewproduct/:productId" element={<ViewProduct />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-email/:token" element={<VerifyEmail/>}/>
                <Route path="/admindashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
                <Route path="/success" element={<AuthenticatedRoute><Success /></AuthenticatedRoute>} />
                <Route path="/cancel" element={<AuthenticatedRoute><Cancel /></AuthenticatedRoute>} />
                <Route path="/history" element={<AuthenticatedRoute><History /></AuthenticatedRoute>} />
                <Route path="/admin/postproduct" element={<AdminRoute><PostProductAdmin /></AdminRoute>} />
                <Route path="/admin/refundrequests" element={<AdminRoute><RefundRequestsManager /></AdminRoute>} />
                <Route path="/admin/users" element={<AdminRoute><UserManager /></AdminRoute>} />
                <Route path="/admin/users/:userId" element={<AdminRoute><ViewUserAdmin /></AdminRoute>} />
                <Route path="/admin/partner/applications" element={<AdminRoute><PartnerApplicationManager /></AdminRoute>} />
                <Route path="/admin/partner/application/:applicationId/process" element={<AdminRoute><PartnerApplicationProcess /></AdminRoute>} />
                <Route path="/admin/deliveries" element={<AdminRoute><DeliveryManager /></AdminRoute>} />
                <Route path="/admin/deliveries/view/:deliveryId" element={<AdminRoute><ViewDelivery /></AdminRoute>} />
                <Route path="/admin/website-statistics" element={<AdminRoute><WebsiteStatistics /></AdminRoute>} />


                {/* Partner Dashboard Route */}
                <Route path="/partner/dashboard" element={<DashboardPage />}>
                  <Route index element={<ProfileManagement />} />
                  <Route path="profile" element={<ProfileManagement />} />
                  <Route path="statistics" element={<Statistics />} />
                  <Route path="products" element={<Products />} />
                  <Route path="sales-history" element={<SalesHistory />} />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
              </div>

              {isModalOpen && (
                <>
                  <div className="age-verification-modal-overlay" />
                  <div className="age-verification-modal-container">
                    <AgeVerificationModal onClose={() => setIsModalOpen(false)} />
                  </div>
                </>
              )}
            </main>
            <Footer />
          </Router>
        </WebSocketProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default App;

