import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css'; 
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { AuthContext } from '../../context/AuthContext'; 
import {LanguageContext} from "../../context/LanguageContext";
import { useWebSocket } from '../../context/WebSocketContext';
import logo from '../../assets/5.png';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Modal from "../../components/Modal/Modal.js"
import MobileMenu from '../MobileMenu/MobileMenu.js';
import ComputerMenu from '../ComputerMenu/ComputerMenu.js';


const Header = () => {
  const { isAuthenticated, user, setUser, setIsAuthenticated } = useContext(AuthContext);
  const { updateLanguage, language } = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  const {socket, ready, registerMessageHandler, unregisterMessageHandler} = useWebSocket();
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const notificationsRef = useRef(null);
  const bellIconRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const unreadNotifications = useRef(user?.notifications?.filter(notification => !notification.read).length || 0);
  const [unreadCount, setUnreadCount] = useState(unreadNotifications.current);

  const handleClickOutside = useCallback((event) => {
    const isClickOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target);
    const isClickOutsideNotifications = notificationsRef.current && !notificationsRef.current.contains(event.target);
    const isClickOutsideBellIcon = bellIconRef.current && !bellIconRef.current.contains(event.target);
    const isClickOutsideLanguageDropdown = languageDropdownRef.current && !languageDropdownRef.current.contains(event.target);
  
    // Close all UI elements if click is outside all of them
    if (isClickOutsideDropdown && isClickOutsideNotifications && isClickOutsideBellIcon && isClickOutsideLanguageDropdown) {
      setShowDropdown(false);
      setShowNotifications(false);
      setShowLanguageDropdown(false);
    } else {
      // If the click is outside the dropdown but inside the notifications, just close the dropdown
      if (isClickOutsideDropdown && !isClickOutsideNotifications) {
        setShowDropdown(false);
      }
      // If the click is outside the notifications but inside the dropdown, just close the notifications
      if (isClickOutsideNotifications && !isClickOutsideDropdown) {
        setShowNotifications(false);
      }
      // If the click is outside the language dropdown, close it
      if (isClickOutsideLanguageDropdown) {
        setShowLanguageDropdown(false);
      }
    }
  }, []);

  const markNotificationsAsRead = () => {
    const updateNotifications = user.notifications.map(notification => 
      notification.read ? notification : {...notification, read:true});
      socket.send(JSON.stringify({
        type: 'notificationsRead',
        data : {
          userId: user._id,
        }
      }));
      setUser(prevUser => ({...prevUser , notifications: updateNotifications}));
      unreadNotifications.current = 0;
      setUnreadCount(0);
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  //WSS
  useEffect(() => {  
    if(ready && socket && isAuthenticated) {
      const handleNotificationUpdate = (data) => {
        console.log("Received Notification:", data);
        setUser(prevUser => ({
          ...prevUser,
          notifications: [...prevUser.notifications, data],
        }));
        unreadNotifications.current = unreadNotifications.current + 1;
        setUnreadCount(unreadNotifications.current);
      };

      registerMessageHandler('notificationUpdate', handleNotificationUpdate);

    return () => {
    };
   }
  }, [ unreadNotifications, isAuthenticated, user, setUser, socket, registerMessageHandler, unregisterMessageHandler, ready]);

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (bellIconRef.current && notificationsRef.current) {
        const bellIconRect = bellIconRef.current.getBoundingClientRect();
        const dropdownRect = notificationsRef.current.getBoundingClientRect();
  
        // Align the far right edge of the dropdown with the far right edge of the bell icon
        notificationsRef.current.style.left = `${bellIconRect.right - dropdownRect.width}px`;
        // Position the dropdown slightly below the bell icon
        notificationsRef.current.style.top = `${bellIconRect.bottom + 20}px`;
      }
    };
  
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition);
  
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [showNotifications]);
  //handles
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    // I put !showNotification because the change right above is not yet done at this point
    //the set function needs a rerender to make showNotification be true, so the logic is not 
    //natural here since we markNotificationaAsRead when showNotifications is still false.
    if (!showNotifications && unreadCount>0) {
      markNotificationsAsRead();
    }
  };

  const toggleLanguageDropdown = () => setShowLanguageDropdown(!showLanguageDropdown);

  const handleWallet = () => {
    navigate("/wallet");
  };

  const handleSettings = () => {
    navigate("/settings")
  };

  const handleHistory = () => {
    navigate("/history")
  };

  const handleAdmin = () => {
    navigate("/admindashboard");
  }

  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    setUser(null);
    setIsAuthenticated(false);

    navigate("/login");
  };

  const confirmLogout = () => {
    handleLogout(); // Call logout function
    setShowLogoutModal(false); // Close the modal
};

  const handleDeleteNotification = async (originalIndex) => {
    try {
      // Make API call to delete the notification by the original index
      await axios.delete(`${API_BASE_URL}/api/notifications/${user._id}/${originalIndex}`);
  
      // Filter out the notification with the specific original index
      const updatedNotifications = user.notifications.filter((_, i) => i !== originalIndex);
  
      setUser(prevUser => ({
        ...prevUser,
        notifications: updatedNotifications
      }));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/notifications/${user._id}`);
      setUser(prevUser => ({
        ...prevUser,
        notifications: []
      }));
    } catch (error) {
      console.error('Error deleting all notifications:', error);
    }
  };

  const handleNotificationClick = (link) => {
    if (link) {
      navigate(link);
    }
  };

  const handleLanguageChange = async (lang) => {
  
    if (isAuthenticated) {
      try {
        const response = await axios.put(`${API_BASE_URL}/api/user/settings/language`, { language: lang });
        if (response.status === 200) {
          updateLanguage(lang); 
          setShowLanguageDropdown(false); 
        }
      } catch (error) {
        console.error('Error updating language:', error);
      }
    } else {
      updateLanguage(lang); 
      setShowLanguageDropdown(false); 
    }
  };

  return (
    <header className='header-page'>
      <img src={logo} className='logo' alt="Logo" onClick={() => navigate('/')} />

      {/* Include MobileMenu */}
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenu={() => setMobileMenuOpen(false)}
        isAuthenticated={isAuthenticated}
        user={user}
        t={t}
        setMobileMenuOpen={setMobileMenuOpen}
        setShowLogoutModal={setShowLogoutModal}
        unreadCount={unreadCount}
        handleNotificationClick={handleNotificationClick}
        handleDeleteNotification={handleDeleteNotification}
        handleDeleteAll={handleDeleteAll}
        markNotificationsAsRead={markNotificationsAsRead}
        // Pass new props
        handleSettings={handleSettings}
        handleWallet={handleWallet}
        handleHistory={handleHistory}
        handleAdmin={handleAdmin}
        language={language}
        updateLanguage={updateLanguage}
      />

      {/* ComputerMenu for desktop navigation */}
      <ComputerMenu
        isAuthenticated={isAuthenticated}
        user={user}
        t={t}
        navigate={navigate}
        showNotifications={showNotifications}
        showDropdown={showDropdown}
        toggleDropdown={toggleDropdown}
        toggleNotifications={toggleNotifications}
        unreadCount={unreadCount}
        handleNotificationClick={handleNotificationClick}
        handleDeleteNotification={handleDeleteNotification}
        handleDeleteAll={handleDeleteAll}
        dropdownRef={dropdownRef}
        bellIconRef={bellIconRef}
        notificationsRef={notificationsRef}
        languageDropdownRef={languageDropdownRef}
        toggleLanguageDropdown={toggleLanguageDropdown}
        language={language}
        showLanguageDropdown={showLanguageDropdown}
        handleLanguageChange={handleLanguageChange}
        setShowLogoutModal={setShowLogoutModal}
        handleSettings={handleSettings}
        handleWallet={handleWallet}
        handleHistory={handleHistory}
        handleAdmin={handleAdmin}
      />

      {/* Modal for logout confirmation */}
      <Modal
        isOpen={showLogoutModal}
        onConfirm={confirmLogout}
        onClose={() => setShowLogoutModal(false)}
        message="logoutConfirmation"
        success={true}
      />
    </header>
  );
  
};

export default Header;
