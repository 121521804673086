// SalesHistory.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';
import { API_BASE_URL } from '../../../config/start.mjs';
import { useTranslation } from 'react-i18next';
import './SalesHistory.css';

const SalesHistory = () => {
  const [salesHistory, setSalesHistory] = useState([]);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchSalesHistory = async () => {
      try {
        if (user && user._id) {
          const response = await axios.get(
            `${API_BASE_URL}/api/partner/sellhistory/${user._id}`
          );
          setSalesHistory(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesHistory();
  }, [user]);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error', { message: error })}</p>;

  return (
    <div className="sales-history">
      <h1>{t('salesHistoryTitle')}</h1>
      <p>{t('salesHistoryDescription')}</p>
      {salesHistory.length > 0 ? (
        <div className="table-container">
          {/* Add a container div for horizontal scrolling */}
          <table className="sales-history-table">
            <thead>
              <tr>
                <th>{t('productName')}</th>
                <th>{t('price')}</th>
                <th>{t('date')}</th>
                <th>{t('status')}</th>
              </tr>
            </thead>
            <tbody>
              {salesHistory
                .slice()
                .reverse()
                .map((sale, index) => (
                  <tr key={index}>
                    <td>{sale.productName}</td>
                    <td>{sale.price.toFixed(2)}€</td>
                    <td>{new Date(sale.date).toLocaleDateString()}</td>
                    <td>{t(`sellStatus.${sale.status}`)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-sales-message">{t('noSalesHistory')}</p>
      )}
    </div>
  );
};

export default SalesHistory;
