import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { useTranslation } from 'react-i18next';
import './PostProductModal.css'; // Import your CSS file

const PostProductModal = ({ onClose, onProductAdded, userId }) => {
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    category: "Clothing",
    reservePrice: '',
    model: '',
    weight: 0.25
  });
  const [images, setImages] = useState([]);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let key in product) {
      formData.append(key, product[key]);
    }
    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      await axios.post(`${API_BASE_URL}/api/partner/${userId}/newproduct`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Product added successfully');
      onProductAdded();
      onClose();
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Error adding product');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1>{t('addNewProduct')}</h1>
        <form onSubmit={handleSubmit}>
          <label>
            {t('productName')}:
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t('startingPrice')}:
            <input
              type="number"
              name="price"
              value={product.price}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t('description')}:
            <textarea
              name="description"
              value={product.description}
              onChange={handleChange}
              style={{resize: 'none'}}
              required
            />
          </label>
          <label>
            {t('images')}:
            <input
              type="file"
              name="images"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              required
            />
          </label>
          <label>
            {t('reservePrice')}:
            <input
              type="number"
              name="reservePrice"
              value={product.reservePrice}
              onChange={handleChange}
              required
            />
          </label>
          <label>
              {t('weight')}:
              <input
                type="text"
                name="weight"
                value={product.weight}
                onChange={handleChange}
              />
            </label>
          <div className="modal-actions">
            <button type="submit" className="btn-primary">
              {t('addProduct')}
            </button>
            <button type="button" className="btn-secondary" onClick={onClose}>
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostProductModal;
