// MobileMenu.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'; // Import useNavigate if needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faBell, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './MobileMenu.css';

const MobileMenu = ({
  mobileMenuOpen,
  toggleMobileMenu,
  isAuthenticated,
  user,
  t,
  setMobileMenuOpen,
  setShowLogoutModal,
  unreadCount,
  handleNotificationClick,
  handleDeleteNotification,
  handleDeleteAll,
  markNotificationsAsRead,
  // New props for account actions
  handleSettings,
  handleWallet,
  handleHistory,
  handleAdmin,
  // Props for language selection
  language,
  updateLanguage
}) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const bellIconRef = useRef(null);
  const notificationsRef = useRef(null);
  const languageDropdownRef = useRef(null);

  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);


  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications && unreadCount > 0) {
      markNotificationsAsRead();
    }
  };

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };
  
  const handleLanguageChange = (lang) => {
    updateLanguage(lang);
    setShowLanguageDropdown(false);
  };
  

  const handleClickOutside = useCallback(
    (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target) &&
        bellIconRef.current &&
        !bellIconRef.current.contains(event.target)
      ) {
        setShowNotifications(false);
      }
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setShowLanguageDropdown(false);
      }
    },
    [setShowNotifications, setShowLanguageDropdown]
  );
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      {/* Mobile Notification, Wallet, and Hamburger Menu */}
      <div className="mobile-notification-wallet">
        {/* Notification Icon (only when authenticated) */}
        {isAuthenticated && (
          <>
            <div
              className="notification-icon-mobile"
              onClick={toggleNotifications}
              ref={bellIconRef}
            >
              <FontAwesomeIcon icon={faBell} />
              {unreadCount > 0 && (
                <span className="notification-badge-mobile">{unreadCount}</span>
              )}
            </div>

            {/* User Wallet */}
            <div className="user-wallet-mobile">
              <div className="wallet-label-mobile">{t('wallet')}</div>
              <div className="wallet-amount-mobile">
                {user?.wallet.amount !== undefined
                  ? `${user.wallet.amount} €`
                  : t('loadingWallet')}
              </div>
            </div>
          </>
        )}

        {/* Hamburger Menu Button (always displayed) */}
        {!mobileMenuOpen && (
          <button
            className="hamburger-menu"
            onClick={() => setMobileMenuOpen(true)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
      </div>

      {/* Mobile Menu Content */}
      {mobileMenuOpen && (
        <div className="mobile-menu">
          {/* Close Button */}
          <div className="mobile-menu-close" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </div>

          {/* Menu Links */}
          <div className="mobile-menu-links">
            {/* Always visible links */}
            <Link to="/" onClick={() => setMobileMenuOpen(false)}>
              {t('home')}
            </Link>
            <Link to="/partners" onClick={() => setMobileMenuOpen(false)}>
              {t('partners')}
            </Link>

            {/* Authentication-based links */}
            {isAuthenticated ? (
              <>
                {/* Partner Links */}
                {user.role === 'partner' && (
                  <Link
                    to="/partner/dashboard"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {t('dashboard')}
                  </Link>
                )}
                {user.role !== 'partner' && (
                  <Link
                    to="/becomepartner"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {t('becomePartner')}
                  </Link>
                )}

                {/* Account Actions */}
                <Link
                  to="/settings"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  {t('settings')}
                </Link>
                <Link
                  to="/wallet"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  {t('wallet')}
                </Link>
                <Link
                  to="/history"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  {t('history')}
                </Link>
                {user.role === 'admin' && (
                  <Link
                    to="/admindashboard"
                    onClick={() => {
                      setMobileMenuOpen(false);
                    }}
                  >
                    {t('admin')}
                  </Link>
                )}
                <Link
                  to="#"
                  onClick={() => {
                    setMobileMenuOpen(false);
                    setShowLogoutModal(true);
                  }}
                >
                  {t('logout')}
                </Link>
              </>
            ) : (
              <Link to="/login" onClick={() => setMobileMenuOpen(false)}>
                {t('login')}
              </Link>
            )}
          </div>

          {/* Language Selector */}
          <div className="mobile-language-selector" ref={languageDropdownRef}>
            <button onClick={toggleLanguageDropdown}>
              {language === 'fr' ? 'FR' : 'EN'}{' '}
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            {showLanguageDropdown && (
              <div className="mobile-language-dropdown">
                <button onClick={() => handleLanguageChange('en')}>EN</button>
                <button onClick={() => handleLanguageChange('fr')}>FR</button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Notification Dropdown */}
      {showNotifications && (
      <div className="notification-dropdown-mobile" ref={notificationsRef}>
        <div className="notification-header-mobile">
          <h4>{t('notifications')}</h4>
          <button className="delete-all-mobile" onClick={handleDeleteAll}>
            {t('deleteAll')}
          </button>
        </div>
        {user.notifications.length > 0 ? (
          user.notifications
            .slice()
            .reverse()
            .map((notification, index) => (
              <div
                key={index}
                className={`notification-item-mobile ${
                  notification.read ? 'read' : 'unread'
                }`}
                onClick={() => handleNotificationClick(notification.link)}
                style={{ cursor: notification.link ? 'pointer' : 'default' }}
              >
                <span>{notification.message}</span>
                <button
                  className="delete-notification-mobile"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNotification(
                      user.notifications.length - 1 - index
                    );
                  }}
                >
                  &times;
                </button>
              </div>
            ))
        ) : (
          <div className="notification-item-mobile no-notifications-mobile">
            {t('noNotifications')}
          </div>
        )}
      </div>
    )}
    </>
  );
};

export default MobileMenu;
