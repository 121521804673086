// src/pages/PartnerDashboard/Main/Main.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import './Main.css'

const DashboardPage = () => {
  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="sidebar-overlay" />
      <div className="dashboard-content">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardPage;
