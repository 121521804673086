// src/components/AgeVerificationModal.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AgeVerificationModal.css';

const AgeVerificationModal = ({ onClose }) => {
  const { t } = useTranslation(); // Hook to get translation function
  const [isOver18, setIsOver18] = useState(false);

  const handleAgeVerification = () => {
    setIsOver18(true);
    sessionStorage.setItem('isOver18', 'true');
    onClose();
  };

  useEffect(() => {
    if (sessionStorage.getItem('isOver18') === 'true') {
      setIsOver18(true);
    }
  }, []);

  return (
    !isOver18 && (
      <div className="age-verification-overlay">
        <div className="age-verification-modal">
          <h2>{t('ageVerification.title')}</h2>
          <p>{t('ageVerification.message')}</p>
          <button className="age-verification-button" onClick={handleAgeVerification}>
            {t('ageVerification.button')}
          </button>
        </div>
      </div>
    )
  );
};

export default AgeVerificationModal;
