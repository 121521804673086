// src/components/ErrorModal/ErrorModal.js

import React from 'react';
import './ErrorModal.css'; // For styling the modal
import { useTranslation } from 'react-i18next'; // Import the translation hook

const ErrorModal = ({ isOpen, onClose, message }) => {
  const { t } = useTranslation(); // Initialize the translation hook

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-errormodal">
      <div className="modal-content-errormodal">
        <h2>{t('error')}</h2> {/* Use translation for "Error" */}
        <p>{t(`${message}`)}</p>
        <button onClick={onClose}>{t('close')}</button> {/* Use translation for "Close" */}
      </div>
    </div>
  );
};

export default ErrorModal;
