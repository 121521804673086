import React from 'react';
import './Modal.css'; 
import { useTranslation } from 'react-i18next';

const Modal = ({ isOpen, onClose, onConfirm, message, success }) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {success ? (<h2>{t('confirmation')}</h2>) : (<h2>{t('error')}</h2>)}
                <p>{t(`${message}`)}</p>
                <div className="modal-buttons">
                    {onClose && (
                        <button className="modal-button" onClick={onClose}>{t("cancel")}</button>
                    )}
                    {onConfirm && (
                        <button className="modal-button" onClick={onConfirm}>{t("confirm")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
