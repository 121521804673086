import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext'; // Adjust the path as needed
import ProductList from '../../../components/ProductList/ProductList';
import { API_BASE_URL } from '../../../config/start.mjs'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import AuctionEditModal from '../../../components/AuctionEditModal/AuctionEditModal';
import PostProductModal from '../../../components/PostProductModal/PostProductModal'; // Import the new modal component
import './Products.css'; // Ensure this CSS file exists

const Products = () => {
  const [products, setProducts] = useState([]);
  const { user } = useContext(AuthContext); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useState(null); // To hold the product being edited
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // For the add product modal

  const fetchProducts = async () => {
    try {
      if (user && user._id) {
        const response = await axios.get(`${API_BASE_URL}/api/partner/products/${user._id}`);
        setProducts(response.data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (user && user._id) {
          const response = await axios.get(`${API_BASE_URL}/api/partner/products/${user._id}`);
          setProducts(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [user]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsEditModalOpen(true); // Open the modal when a product is clicked
  };

  const handleSave = async (updatedProduct) => {
    try {
      // Send updated product data to the backend
      await axios.put(`${API_BASE_URL}/api/partner/products/${selectedProduct._id}`, updatedProduct);
      
      // Update product in the local state
      setProducts((prevProducts) =>
        prevProducts.map((prod) => (prod._id === selectedProduct._id ? { ...prod, ...updatedProduct } : prod))
      );
  
      setIsEditModalOpen(false); // Close modal after save
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false); // Close edit modal without saving
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false); // Close add modal without saving
  };

  const handleProductAdded = () => {
    // Re-fetch products after adding a new one
    fetchProducts();
  };

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error', { message: error })}</p>;

  return (
    <div className="products-page">
      <h1>{t('productsTitle')}</h1>
      <p>{t('productsDescription')}</p>
      
      {/* Centered Add New Product Button */}
      <div className="add-product-button-container">
        <button className="add-product-button" onClick={() => setIsAddModalOpen(true)}>
          {t('addNewProduct')}
        </button>
      </div>
  
      <ProductList products={products} handleProductClick={handleProductClick} />
  
      {isEditModalOpen && selectedProduct && (
        <AuctionEditModal 
          product={selectedProduct} 
          onSave={handleSave} 
          onClose={handleCloseEditModal} 
        />
      )}
  
      {isAddModalOpen && (
        <PostProductModal 
          onClose={handleCloseAddModal} 
          onProductAdded={handleProductAdded} 
          userId = {user._id}
        />
      )}
    </div>
  );
};

export default Products;
