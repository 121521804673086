import React, { useState, useEffect } from 'react';
import './ProductList.css';
import { API_BASE_URL } from '../../config/start.mjs';
import { useTranslation } from 'react-i18next';

const ProductList = ({ products, handleProductClick }) => {
  const { t } = useTranslation();
  const [pulseSpeed, setPulseSpeed] = useState('1s'); // Default pulse speed
  const defaultDate = new Date('1970-01-01T00:00:00Z'); // The default date to check against

  const isAuctionEnded = (auctionDate) => {
    const now = new Date();
    const auctionEndDate = new Date(auctionDate);
    return now > auctionEndDate;
  };

  const isAuctionScheduled = (auctionStartDate) => {
    const now = new Date();
    const startDate = new Date(auctionStartDate);
    return now < startDate;
  };

  const isDefaultDate = (date) => {
    const current = new Date(date);
    return current.getTime() === defaultDate.getTime();
  };

  const getTimeRemaining = (endDate) => {
    const auctionEndDate = new Date(endDate);

    if (isNaN(auctionEndDate.getTime())) {
      console.error('Invalid auction end date:', endDate);
      return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const total = auctionEndDate - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const Countdown = ({ auctionEndDate }) => {
    const [timeLeft, setTimeLeft] = useState(getTimeRemaining(auctionEndDate));

    useEffect(() => {
      const updateTimer = () => {
        setTimeLeft(getTimeRemaining(auctionEndDate));
      };

      const timeRemaining = getTimeRemaining(auctionEndDate);
      if (timeRemaining.total <= 60 * 1000) {
        setPulseSpeed('0.25s'); // Less than 1 minute
      } else if (timeRemaining.total <= 60 * 60 * 1000) {
        setPulseSpeed('0.5s'); // Less than 1 hour
      } else {
        setPulseSpeed('1s'); // More than 1 hour
      }

      const timerInterval = setInterval(updateTimer, 1000);
      return () => clearInterval(timerInterval);
    }, [auctionEndDate]);

    if (timeLeft.total <= 0) {
      return <></>;
    }

    return (
      <p className="auction-countdown" style={{ animationDuration: pulseSpeed }}>
        {t('timeRemaining')}: {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
      </p>
    );
  };

  return (
    <div className='product-list'>
      {products && products.length > 0 ? (
        products.map(product => (
          <div
            key={product?._id} // Optional chaining to safely access _id
            className='product-item'
            onClick={() => handleProductClick(product)}
          >
            <div className='img-container'>
              {product?.images && product.images.length > 0 ? (
                <img src={`${API_BASE_URL}/${product.images[0]}`} alt={product.name} />
              ) : (
                <img src={`${API_BASE_URL}/uploads/products/placeholder_image.png`} alt={product.name} /> // Add your placeholder image path here
              )}
            </div>
            <h2 className='product-list-h2'>{product?.name || 'Unnamed Product'}</h2>
            
            {/* Handle default date case */}
            {isDefaultDate(product.auctionStartDate) || isDefaultDate(product.auctionEndDate) ? (
              <p className='auction-scheduled'>
                {t('auctionNotScheduled')}
              </p>
            ) : product.isCanceled ? ( // Check if the auction is canceled
              <p className='auction-canceled'>
                {t('auctionCanceled')}
              </p>
            ) : isAuctionScheduled(product.auctionStartDate) ? (
              <p className='auction-scheduled'>
                {t('auctionScheduledOn')}: {new Date(product.auctionStartDate).toLocaleString()}
              </p>
            ) : isAuctionEnded(product.auctionEndDate) ? (
              <p className='auction-ended'>{t('auctionEnded')}</p>
            ) : (
              <>
                <p className='current-bid'>
                  {t('currentBid')}: {product.bid ? `${product.bid.amount} €` : `${product.startingPrice} €`}
                </p>
                {/* Countdown Timer */}
                <Countdown auctionEndDate={product.auctionEndDate} />
              </>
            )}
          </div>
        ))
      ) : (
        <p>{t('noProductsAvailable')}</p> // Message when no products are available
      )}
    </div>
  );
};

export default ProductList;
