import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AuctionEditModal.css';

const AuctionEditModal = ({ product, onSave, onClose }) => {
  const { t } = useTranslation();
  const [auctionStartDate, setAuctionStartDate] = useState('');
  const [auctionEndDate, setAuctionEndDate] = useState('');
  const [description, setDescription] = useState(product.description || '');
  const [startError, setStartError] = useState('');
  const [endError, setEndError] = useState('');
  const [isAuctionStarted, setIsAuctionStarted] = useState(false);

  const MIN_AUCTION_LENGTH_HOURS = 0;



  const formatDateToLocalString = (dateString) => {
    if (!dateString || dateString === new Date('1970-01-01T00:00:00Z').toISOString()) return ''; // Check for the reset date
    const UTCdate = new Date(dateString);
    return new Date(UTCdate.getTime() - UTCdate.getTimezoneOffset() * 60 * 1000)
      .toLocaleString('sv-SE', { timeZone: 'UTC' })
      .slice(0, 16);
  };

  useEffect(() => {
    const now = new Date();
    const productStartDate = new Date(product.auctionStartDate);
    setAuctionStartDate(formatDateToLocalString(product.auctionStartDate));
    setAuctionEndDate(formatDateToLocalString(product.auctionEndDate));
    
    // Check if the auction has started, excluding the reset date
    setIsAuctionStarted(productStartDate > new Date('1970-01-01T00:00:00Z') && productStartDate <= now);
    setDescription(product.description || '');
  }, [product]);

  const validateStartDate = (startDate) => {
    const selectedDateLocal = new Date(startDate);
    const now = new Date();
    return selectedDateLocal >= now;
  };

  const calculateMinEndDate = (startDate, minAuctionLengthHours) => {
    const selectedDateLocal = new Date(startDate);
    const now = new Date();
    const newEndDate = new Date(
      selectedDateLocal.getTime() - now.getTimezoneOffset() * 60 * 1000 + minAuctionLengthHours * 60 * 60 * 1000
    );
    return newEndDate.toLocaleString('sv-SE', { timeZone: 'UTC' }).slice(0, 16);
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (!isAuctionStarted && validateStartDate(selectedStartDate)) {
      setAuctionStartDate(selectedStartDate);
      setStartError('');
      const localEndDateString = calculateMinEndDate(selectedStartDate, MIN_AUCTION_LENGTH_HOURS);
      setAuctionEndDate(localEndDateString);
    } else if (!isAuctionStarted) {
      setStartError(t('startDateError'));
    }
  };

  const validateEndDate = (startDate, endDate) => {
    const selectedStartDate = new Date(startDate);
    const selectedEndDateLocal = new Date(endDate);
    const minEndDate = new Date(selectedStartDate.getTime() + MIN_AUCTION_LENGTH_HOURS * 60 * 60 * 1000);
    return selectedEndDateLocal >= minEndDate;
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (!isAuctionStarted && !validateEndDate(auctionStartDate, selectedEndDate)) {
      setEndError(t('endDateError'));
    } else if (!isAuctionStarted) {
      setEndError('');
    }
    setAuctionEndDate(selectedEndDate);
  };

  const validateBeforeSave = () => {
    if (isAuctionStarted) {
      return true;
    }
    
    let valid = true;

    if (!validateStartDate(auctionStartDate)) {
      setStartError(t('startDateError'));
      valid = false;
    }

    const startDate = new Date(auctionStartDate);
    const endDate = new Date(auctionEndDate);

    if (!validateEndDate(startDate, endDate)) {
      setEndError(t('endDateError'));
      valid = false;
    }

    return valid;
  };

  const handleSave = () => {
    if (validateBeforeSave()) {
      // Only send the description if the auction has started
      let dataToSend
      if (isAuctionStarted){
        dataToSend = {description}
      } else {
        const formattedStartDate = new Date(auctionStartDate)

        const formattedEndDate = new Date(auctionEndDate)
        console.log(formattedStartDate, formattedEndDate)

        dataToSend = { auctionStartDate: formattedStartDate, auctionEndDate: formattedEndDate, description };
      }
      onSave(dataToSend);
    }
  };

  const now = new Date();
  const minStartDate = new Date(now.getTime() + 60 * 1000);
  const minStartDateString = minStartDate.toISOString().slice(0, 16);

  return (
    <div className="modal-overlay-auctionedit">
      <div className="modal-content-auctionedit">
        <h2>{t('editAuctionTitle')}</h2>
        <h3>{t('productName')}: {product.name}</h3>
        
        <label>
          {t('auctionStartDate')}:
          {startError && <p className="error-message">{startError}</p>}
          <input 
            type="datetime-local"
            value={auctionStartDate}
            min={minStartDateString}
            onChange={handleStartDateChange}
            disabled={isAuctionStarted}
          />
        </label>

        <label>
          {t('auctionEndDate')}:
          {endError && <p className="error-message">{endError}</p>}
          <input 
            type="datetime-local"
            value={auctionEndDate}
            onChange={handleEndDateChange}
            disabled={isAuctionStarted}
          />
        </label>

        <label>
          {t('description')}:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{resize: 'none'}}
          />
        </label>
        
        <div className="modal-actions-auctionedit">
          <button onClick={handleSave}>{t('save')}</button>
          <button onClick={onClose}>{t('cancel')}</button>
        </div>
      </div>
    </div>
  );
};

export default AuctionEditModal;
